<template>
  <header>
    <div class="pub-flex-row pub-justify-between group">
      <a href="#/" class="pub-self-center image"><img class=""
          src="../../assets/844e3644260472931f1127c7cefa8b55.png" /></a>

      <nav class="pub-flex-row pub-self-start group_2 space-x-66" :class="{ on:navN === 1}">
        <RouterLink to="/">
          <div class="pub-flex-row pub-items-baseline space-x-4" @click="navC(0)" :class="{on : active === 0}">
            <span class="font_3">Platform</span>
            <span class="font_1 ">平台</span>
          </div>
        </RouterLink>
        <RouterLink to="/about">
          <div class="pub-flex-row space-x-4" @click="navC(1)" :class="{on : active === 1}">
            <span class="font_3">Profile</span>
            <span class="font_1 ">关于</span>
          </div>
        </RouterLink>
        <RouterLink to="/news">
          <div class="pub-flex-row pub-items-center space-x-4" @click="navC(2)" :class="{on : active === 2}">
            <span class="font_3">Information</span>
            <span class="font_1 ">资讯</span>
          </div>
        </RouterLink>
      </nav>

      <div class="at_on btn_nav_m">
        <div class="burger3" :class="{ open:navN === 1}" @click="navO()">
          <div class="icon_l"></div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      navN:0,
      active:0
    };
  },

  methods: {
    navO(){

      if(this.navN === 0){
        this.navN = 1
      }else{
        this.navN = 0
      }
    },
    navC(i){
      this.active = i

    }
  },
};
</script>

<style scoped lang="scss">
$on_color1: rgba(1, 1, 1, 1);

header {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 55;

  .group {
    padding: 0.48rem .2rem 0.42rem;
    width: 14.15rem;
    max-width: 100%;
    margin: 0 auto;

    .image {
      width: 2.12rem;
      height: 0.56rem;
      display: block;

      img {
        width: 100%;
        display: block;
      }
    }

    .group_2 {
      margin-top: 0.37rem;

      .space-x-4 {
        &>*:not(:first-child) {
          margin-left: 0.04rem;
        }

        .font_3 {
          font-size: 0.2rem;
          font-family: PingFang;
          line-height: 0.16rem;
          color: rgb(62, 58, 57);
          transition: color .5s ease;
        }

        .font_1 {
          font-size: 0.2rem;
          font-family: PingFang;
          line-height: 0.2rem;
          color: rgb(62, 58, 57);
          transition: color .5s ease;
        }

        .text {
          line-height: 0.19rem;
        }

        .text_2 {
          line-height: 0.19rem;
        }
      }

      a{
        &:hover{
          .space-x-4 {
        

        .font_3 {
         
          color: rgb(255, 63, 61);
        }

        .font_1 {
         
          color: rgb(255, 63, 61);
        }

      }
        }
      }
      .router-link-exact-active{
        .space-x-4 {
        

        .font_3 {
         
          color: rgb(255, 63, 61);
        }

        .font_1 {
         
          color: rgb(255, 63, 61);
        }

      }
      }
    }

    .space-x-66 {
      &>*:not(:first-child) {
        margin-left: 0.66rem;
      }
    }
  }




  .btn_nav_m {
    display: none;
  }

  .burger3 {
    transform-origin: 100% 50%;
    transform: scale(.75);
    width: .54rem;
    height: .54rem;
    position: relative;
    transition-duration: 1s;
    display: inline-block;
    cursor: pointer;


    .icon_l {
      height: .06rem;
      width: .27rem;
      top: .2rem;
      background-color: $on_color1;
      border-radius: 20px;
      position: absolute;
      transition-duration: 0.5s;

      &:before {
        left: 0;
        position: absolute;
        top: -.18rem;
        height: .06rem;
        width: .54rem;
        background-color: $on_color1;
        content: "";
        border-radius: 20px;
        transition-duration: 0.5s;
      }

      &:after {
        left: 0;
        position: absolute;
        top: .18rem;
        height: .06rem;
        width: .54rem;
        background-color: $on_color1;
        content: "";
        border-radius: 20px;
        transition-duration: 0.5s;
      }


    }


    &.open {
      .icon_l {
        background: transparent !important;


        &::before {
          transition: transform 0.5s;
          transform: rotateZ(45deg) translate(.1rem, .15rem);
        }

        &::after {
          transition: transform 0.5s;
          transform: rotateZ(-405deg) translate(.1rem, -.15rem);
        }
      }
    }

  }

  @media screen and (orientation: portrait) {

    .group {
      padding: 0 .38rem;

      .space-x-66>*:not(:first-child) {
        margin-left: 0;
      }

      .image {
        position: relative;
        z-index: 3;
      }

      .group_2 {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #fff;
        transform: translateX(120%);
        transition: transform .5s ease;
        margin: 0;
        padding: 1.5rem .38rem .5rem;
        display: block;

        a {
          display: block;
          .space-x-4 {
            .font_3,
            .font_1 {
              font-size: .28rem;
              line-height: 3;
            }

          }
        }

        &.on {
          transform: none;
        }

      }
    }
    .btn_nav_m {
    display: block;

  }
  }

  

}
</style>