<template>
  <div class="pub-flex-col page">
    <nav-box></nav-box>

    <div class="pub-flex-col space-y-5">
      <div class="pub-flex-col">
        <div class="pub-flex-col group_3">
          <div class="pub-flex-row section">
            <div class="lb_box">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div>
                      <div
                        class="
                          pub-flex-col pub-flex-auto pub-self-start
                          group_5
                          space-y-
                          ban_tex
                        "
                      >
                        <div class="pub-flex-col">
                          <span data-depth="10" class="pub-self-start text_5"
                            >Damei</span
                          >
                          <span
                            data-depth="10"
                            class="pub-self-start font_4 text_6"
                            >大美云集</span
                          >
                          <span
                            data-depth="10"
                            class="pub-self-start font_4 text_7"
                            >专注赛事管理系统</span
                          >
                        </div>
                        <div
                          data-depth="10"
                          class="
                            pub-flex-col
                            pub-justify-start
                            pub-items-center
                            pub-self-start
                            text-wrapper
                          "
                        >
                          <span class="text_8"
                            >申请试用

                            <div class="pub-flex-col section_2 space-y-16 pos">
                              <div
                                class="
                                  pub-flex-col
                                  pub-justify-start
                                  pub-items-center
                                  image-wrapper
                                "
                              >
                                <img
                                  class="image_5"
                                  src="../../assets/9d65b5f56c8eb0bea560004bfd770b03.png"
                                />
                              </div>
                              <span class="pub-self-center text_9"
                                >微信扫码申请</span
                              >
                            </div>
                          </span>
                        </div>
                      </div>

                      <div class="pub_img_box">
                        <img class="pc_sty" src="../../assets/001.jpg" alt="" />
                        <img class="m_sty" src="../../assets/m001.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 如果需要导航按钮 -->
                <!-- <div class="swiper-button-prev">
                  <img class="pub-shrink-0 pub-self-start image_3 image_4"
                    src="../../assets/809a93c32cbbe3623c47138ae787f9e8.png" />
                </div>
                <div class="swiper-button-next">
                  <img class="image_3" src="../../assets/6b37b54dfb0c2101c86106aaa29bd5b2.png" />
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="pub-flex-col pub-justify-start pub-relative group_6">
          <div class="pub-flex-col section_3">
            <div class="pub-flex-col pub-items-start pub-self-start space-y-12">
              <span class="font_5 text_10" data-depth="10"
                >System introduction</span
              >
              <span class="font_6 text_11" data-depth="10">系统简介</span>
            </div>
            <span class="text_12" data-depth="10">
              大美云集学会/协会/商会/企业官网系统主要针对学会/协会/商会/企业官网功能单一，展示繁琐，内容单调的痛点，结合学会/协会/商会/企业以会员为核心，以活动为核心的内容特点，为学会/协会/商会/企业最大化服务会员，高效组织活动而开发，集技术稳定，管理高效，功能完善为一体，是学会/协会/商会/企业建立对外宣传平台，激发会员主观能动性，服务会员，举办活动的行业定制化系统。
            </span>
            <div class="pub-flex-row pub-justify-between group_7">
              <div
                class="pub-flex-col pub-justify-start pub-relative group_8"
                data-depth="10"
              >
                <div class="pub-flex-col section_5">
                  <span class="font_7 text_13">Business flow</span>

                  <div class="pub-flex-row group_11 space-x-22">
                    <div class="pub-shrink-0 group_10 view_5">
                      <span class="font_8">
                        建赛3分钟
                        <br />
                      </span>
                      <span class="font_8">
                        报名3分钟
                        <br />
                      </span>
                      <span class="font_8">
                        缴费3秒钟
                        <br />
                      </span>
                      <span class="font_8">投票1键推</span>
                    </div>
                    <div class="pub-shrink-0 group_10 view_4">
                      <span class="font_8">
                        线上考场
                        <br />
                      </span>
                      <span class="font_8">
                        评审轻松评
                        <br />
                      </span>
                      <span class="font_8">
                        成绩放榜
                        <br />
                      </span>
                      <span class="font_8">电子奖状</span>
                    </div>
                  </div>

                  <div
                    class="pub-flex-row pub-items-center group_12 space-x-122"
                  >
                    <img
                      class="pub-shrink-0 image_7"
                      src="../../assets/f5d80851861fbf83bd63050ed4f3a241.png"
                    />
                    <span class="font_9">业务流转</span>
                  </div>
                </div>
                <img
                  class="image_6 pos_3"
                  src="../../assets/fc472da85bbde42bfd857825f8d58fd3.png"
                />
              </div>

              <div
                class="pub-flex-col pub-justify-start pub-relative group_8"
                data-depth="10"
              >
                <div class="pub-flex-col section_5">
                  <span class="font_7 text_13">Intelligent management</span>

                  <div class="pub-flex-row group_11 space-x-22">
                    <div class="pub-shrink-0 group_10 view_5">
                      <span class="font_8">
                        赛程灵活设置
                        <br />
                      </span>
                      <span class="font_8">
                        组别智能分类
                        <br />
                      </span>
                      <span class="font_8">
                        作品格式任选
                        <br />
                      </span>
                      <span class="font_8">个人数据中心</span>
                    </div>
                    <div class="pub-shrink-0 group_10 view_4">
                      <span class="font_8">
                        数据统计看板
                        <br />
                      </span>
                      <span class="font_8">
                        服务全程对接
                        <br />
                      </span>
                      <span class="font_8">
                        数据稳定安全
                        <br />
                      </span>
                      <span class="font_8">性能快速高效</span>
                    </div>
                  </div>

                  <div
                    class="pub-flex-row pub-items-center group_12 space-x-122"
                  >
                    <img
                      class="pub-shrink-0 image_7"
                      src="../../assets/95e5f0f5dafc7e5698f9e57e3edba26f.png"
                    />
                    <span class="font_9">智能管理</span>
                  </div>
                </div>
                <img
                  class="image_6 pos_3"
                  src="../../assets/eeebd77e3de114742d86a518a693274a.png"
                />
              </div>

              <div
                class="pub-flex-col pub-justify-start pub-relative group_8"
                data-depth="10"
              >
                <div class="pub-flex-col section_5">
                  <span class="font_7 text_13">Promotion tools</span>

                  <div class="pub-flex-row group_11 space-x-22">
                    <div class="pub-shrink-0 group_10 view_5">
                      <span class="font_8">
                        成果云展览
                        <br />
                      </span>
                      <span class="font_8">
                        VR/系列展览
                        <br />
                      </span>
                      <span class="font_8">
                        全页面生成海报
                        <br />
                      </span>
                      <span class="font_8 text_17">微信/抖音/小红书转发</span>
                    </div>
                    <div class="pub-shrink-0 group_10 view_4">
                      <span class="font_8">
                        线上无限裂变
                        <br />
                      </span>
                      <span class="font_8">
                        线上拉新围观
                        <br />
                      </span>
                      <span class="font_8">
                        排名榜单展示
                        <br />
                      </span>
                      <span class="font_8">返佣/分销灵活</span>
                    </div>
                  </div>

                  <div
                    class="pub-flex-row pub-items-center group_12 space-x-122"
                  >
                    <img
                      class="pub-shrink-0 image_7"
                      src="../../assets/1c858c726d05f04b8052a3c8e72229e7.png"
                    />
                    <span class="font_9">推广工具</span>
                  </div>
                </div>
                <img
                  class="image_6 pos_3"
                  src="../../assets/22ea512c5639a64f8385e41c6a944039.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="section_7">
          <div class="pub-flex-row pub-justify-between">
            <div class="pub-flex-col pub-items-start pub-self-start group_13">
              <span class="text_19">Features</span>
              <span class="font_6 text_20">特色功能</span>
              <span class="font_11 text_21">大美云集帮您直连赛事</span>
            </div>
            <img
              class="pub-self-center image_10"
              src="../../assets/f06a54b22a6b1a3e222a7ed714bd3298.png"
            />
          </div>
        </div>

        <div class="pub-flex-col pub-self-center group_14">
          <div>
            <img
              class="image_11"
              src="../../assets/ef4444e00880f82403c0ca05886a2d1b.png"
            />
            <span class="font_10">3分钟创建比赛</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                自定义比赛
                <br />
                微信一站式操作
                <br />
                赛程智能运转
                <br />
                入口海报一键生成
                <br />
                小程序H5任意选择
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/81c5b53d4660818cb162ffdb1676ef2c.png"
            />
            <span class="font_10">赛程灵活多样</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                单场比赛<br />
                多段赛 (海选/复赛/总决赛)<br />
                多区域规模赛 (市赛/省赛/<br />
                国赛/地区)
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/ec32d3fc42b02732b835a58239b1e744.png"
            />
            <span class="font_10">多维度组别分类</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                类别/组别<br />
                团队/个人/年龄<br />
                电话/地址/选送单位<br />
                推荐人/指导老师
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/4c8a830375b5258d5d1aa2d5f3c5a702.png"
            />
            <span class="font_10">作品格式</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                文图/视频/音频/文件<br />
                单件/多件<br />
                指定作品/自由发挥
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/b64d3d583d1d8a173ed2a50d3f577c57.png"
            />
            <span class="font_10">选手无障碍报名</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                微信一键进入比赛<br />
                手机号登录立即报名<br />
                3分钟完成报名
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/3ff109752f78b00be33f881422107f30.png"
            />
            <span class="font_10">报名费设置</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                根据公益/商业设置免费<br />
                收费/交作品<br />
                缴费顺序灵活设置<br />
                即时缴费/退费
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/b1e8711c91a1dffab79457e83b164649.png"
            />
            <span class="font_10">投票拉票宣传</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                投票可选是否开放可选<br />
                每人1天1票免费投<br />
                积分兑换可投票数<br />
                防恶意刷票智能机器人巡逻
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/dab91fc367a30a712ad4107201a2b71d.png"
            />
            <span class="font_10">线上模拟考场</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                作品批量/指定分组<br />
                可分配1个/多个评委<br />
                可分配单次评/复评<br />
                即时算分/防作弊机制
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/2a87851ecd747acc35668a825edc4505.png"
            />
            <span class="font_10">评委登录</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                待评/已评可看<br />
                未提交前修改分数<br />
                不同权限<br />
                评审纪录永久保存
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/6cfdfa1ecd8c276403f76dbf0c4cdd68.png"
            />
            <span class="font_10">排名榜</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                人气式排名<br />
                分数顺序式排名<br />
                各组别排名<br />
                排名榜展示推广
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/41294e2727c052e8e76929dcd646dcb5.png"
            />
            <span class="font_10">成果云展览</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                文图/视频展示<br />
                VR模拟展示<br />
                3D空间展示<br />
                专题页展示
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/253bffe695bd98311b3118410ab209d5.png"
            />
            <span class="font_10">电子奖状</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                多套奖状模版<br />
                防伪唯一编号<br />
                存档永久可查<br />
                批量下载/打印
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/4d22a248e956242aed6ab7bb66614142.png"
            />
            <span class="font_10">返佣/分销</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                无感分销体验<br />
                一级/二级灵活分配机制<br />
                实时到账，安全提现<br />
                智能化管账管团队
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/46a376823bf80570472768a304b20d55.png"
            />
            <span class="font_10">数据统计表</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                账单/订单<br />
                项目数据分析<br />
                分类分组统计表<br />
                信息安全数据包一键交付
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/a283630e53b2cac5eab80ea286f86b42.png"
            />
            <span class="font_10">个人中心</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                成绩查询<br />
                订单/积分<br />
                钱包详情<br />
                技术客服
              </p>
            </div>
          </div>
          <div>
            <img
              class="image_11"
              src="../../assets/2caf39918916428b6e9193ce02411fd2.png"
            />
            <span class="font_10">高品质服务</span>
            <div class="pub-shrink-0 group_10 view_6">
              <p class="font_12">
                反馈问题当天响应<br />
                系统运行安全平稳<br />
                性能优化持续领先<br />
                二对一服务
              </p>
            </div>
          </div>
        </div>

        <div class="pub-flex-col section_8">
          <div class="ben_con">
            <span class="pub-self-start font_5 text_28">System benefits</span>
            <span class="pub-self-start font_6 text_29">系统效益</span>
            <div class="pub-flex-row pub-items-end group_15 view_15 space-x-90">
              <div class="pub-flex-row space-x-18">
                <div class="group_35 pc_sty">
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/90b9bd9b1f82bc88e67590f657fdf427.png"
                    />信息输入更快捷方便，降低成本，提高效
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/e7d71a8e90a645381ab9d30fb0669c9f.png"
                    />操作流程灵活规范，提高活动组委会信息交流效率，提高客户服务体验
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/90b9bd9b1f82bc88e67590f657fdf427.png"
                    />打通各环节单位，减少重复劳动，节省人力，时间，提高数据准确性和共享性
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/e7d71a8e90a645381ab9d30fb0669c9f.png"
                    />为管理层、组委会提供决策信息
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/90b9bd9b1f82bc88e67590f657fdf427.png"
                    />财务结算功能，实现赛事/活动业务的自动计费、收费、统计功能
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/e7d71a8e90a645381ab9d30fb0669c9f.png"
                    />方便的价格调整功能，易于赛事/活动的人性化运作
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/90b9bd9b1f82bc88e67590f657fdf427.png"
                    />仿真的考场模拟功能，能够实时反映当前报名及作品分配情况
                    <br />
                  </span>
                  <span class="font_13">
                    <img
                      class="image_19"
                      src="../../assets/e7d71a8e90a645381ab9d30fb0669c9f.png"
                    />提供方便的查找数据功能、报名审核功能，退款功能，报名电话，作品，作者，
                    <br />指导老师选送机构等信息即时显示功能等
                  </span>
                </div>
                <div class="group_35 m_sty">
                  <span class="font_13">
                    1、信息输入更快捷方便，降低成本，提高效<br />
                    2、操作流程灵活规范，提高活动组委会信息交流效率，提高客户服务体验<br />
                    3、打通各环节单位，减少重复劳动，节省人力，时间，提高数据准确性和共享性<br />
                    4、为管理层、组委会提供决策信息<br />
                    5、财务结算功能，实现赛事/活动业务的自动计费、收费、统计功能<br />
                    6、方便的价格调整功能，易于赛事/活动的人性化运作<br />
                    7、仿真的考场模拟功能，能够实时反映当前报名及作品分配情况<br />
                    8、提供方便的查找数据功能、报名审核功能，退款功能，报名电话，作品，作者，指导老师选送机构等信息即时显示功能等
                  </span>
                </div>
              </div>
              <img
                class="image_17"
                src="../../assets/471bbe61a494f2a53be335d6ddd38be5.png"
              />
            </div>
          </div>
        </div>

        <div class="pub-flex-col pub-self-center group_37 space-y-69">
          <div class="pub-flex-col pub-items-end space-y-13">
            <span class="font_11 text_30">SAAS management system</span>
            <span class="font_6 text_31">SAAS管理系统</span>
          </div>

          <div class="pub-flex-col xt_con">
            <div class="pub-flex-row">
              <img src="../../assets/267bc36776d42b625490b7403f5af888.png" />
              <span class="font_9">主流移动设备</span>
              <div class="group_40">
                <span class="font_14">
                  采用主流移动平台，支持各类移动硬件设备
                  <br />
                  （手机、平板、PDA手持终端），数据实时采集、
                  <br />
                  信息永不滞后。
                </span>
              </div>
            </div>

            <div class="pub-flex-row">
              <img src="../../assets/31d76c5b4d78f767bee2b3a6913d16c8.png" />
              <span class="font_9 text_32">简单易用，成本低廉</span>
              <div class="group_40">
                <span class="font_14">
                  基于系统交互友好、可操作性强、设计人性化等特点，
                  <br />
                  员工无需培训就能轻松上手，大美云集客服人员
                  <br />
                  可远程实时解决用户问题降低维护成本。
                </span>
              </div>
            </div>

            <div class="pub-flex-row">
              <img src="../../assets/90fb6e434b3d1805da66604923cb996a.png" />
              <span class="font_9">多维消除痛点盲区</span>
              <div class="group_40">
                <span class="font_14">
                  深度融合现代商会/学会前沿痛点和资深比赛
                  <br />
                  活动策划专家多年实战经验，管理精髓渗入
                  <br />
                  系统的每一个功能、每一个表单。
                </span>
              </div>
            </div>

            <div class="pub-flex-row">
              <img src="../../assets/421890276ab37449975e99f9cccd8746.png" />
              <span class="font_9">一体化管理，灵活扩展</span>
              <div class="group_40">
                <span class="font_14">
                  集成商会/学会所需业务管理功能（PMS+OA+HR+财务）
                  <br />
                  于一套系统，提供全面的解决方案，亦可为商会/学会二次
                  <br />
                  开发或对接其他业务系统。
                </span>
              </div>
            </div>

            <div class="pub-flex-row">
              <img src="../../assets/34c72236f52130679734e55a609e0ca1.png" />
              <span class="font_9">秘书-小美</span>
              <div class="group_40">
                <span class="font_14">
                  AI机器人"小美"内置大美云集完善的帮助教程，
                  <br />
                  精准锁定并解决您的疑问，成为您的贴身工作顾问。
                </span>
              </div>
            </div>
            <div class="pub-flex-row">
              <img src="../../assets/e76f50de71a30bf7fdf2969727cfd6f9.png" />
              <span class="font_9">安全性，稳定性</span>
              <div class="group_40">
                <span class="font_14">
                  多加密机制，多备份方案，多重身份认证保障系统稳定安全运行，
                  <br />
                  数据存储在阿里云端服务器集群无需担心泄漏。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from '../../components/navbox/navbox.vue'
import FootBox from '../../components/footbox/footbox.vue'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import wx from 'weixin-js-sdk'

import gsap from 'gsap'

export default {
  components: { NavBox, FootBox },
  data() {
    return {
      dateVisible: false,
      currentDate: null,
      Version:2,
    }
  },
  created() {
			if(this.$wechat.isWeixin()){
				let shareObj = {
					
          title: this.Version == 1 ? '赛事系统' : '赛事管理系统', // 分享标题
							desc: this.Version == 1 ? '集技术的先进性、管理的有效性于一体的高效管理信息系统。' :
								'大美云集学会/协会/商会/企业赛事活动系统简介', // 分享描述
							link: location.href, // 分享链接，该链接必须与当前页面路径一致
							imgUrl: window.location.origin + '/static/img/shareLogo.jpg', // 分享图标

				}
				this.$wechat.wxShare(shareObj)
			}
      
  },

  mounted() {
    this.onLb()

    // const tl = gsap.timeline({
    //   defaults: {
    //     ease: "none"
    //   },
    //   scrollTrigger: {
    //     start: 0,
    //     end: "max",
    //     scrub: 2,
    //     markers:true
    //   }
    // });

    // gsap.utils.toArray("[data-depth]").forEach((layer) => {
    //   console.log(layer)
    //   tl.from(layer, { y: 200 ,opacity:0 });
    // });
  
    
  },

  methods: {
    onLb() {
      new Swiper('.lb_box .swiper-container', {
        slideActiveClass: 'active',
        autoplay: 5000,
        speed: 600,
        prevButton: '.lb_box .swiper-button-prev',
        nextButton: '.lb_box .swiper-button-next',
        observer: true,
        observeParents: true,
      })
    },
  
  },
 
}
</script>

<style scoped lang="scss">
.page {
  background-color: rgb(255, 255, 255);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .space-y-5 {
    & > *:not(:first-child) {
      margin-top: 0.05rem;
    }

    .group_3 {
      .section {
        background-color: #ececec;

        .lb_box {
          width: 100%;

          .swiper-container {
            overflow: visible;

            .swiper-button-prev,
            .swiper-button-next {
              background-image: none;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 0.24rem;
                height: 0.42rem;
                display: block;
              }
            }
          }

          .swiper-slide {
            width: 100%;

            > div {
              position: relative;
              width: 14.15rem;
              padding-left: 0.2rem;
              padding-right: 0.2rem;
              margin: 0 auto;
              max-width: 100%;

              .ban_tex {
                position: absolute;
                width: 100%;
                top: 0;
                z-index: 1;
                left: 0;
                padding-left: 0.05rem;
                padding-top: 0.3rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
              }
            }

            .pub_img_box {
              &::before {
                margin-top: 41.309%;
              }
            }
          }
        }

        .group_5 {
          .text_5 {
            color: rgb(255, 63, 61);
            font-size: 0.72rem;
            font-family: AkzidenzGroteskBQ;
            line-height: 0.51rem;
          }

          .font_4 {
            font-size: 0.38rem;
            font-family: PingFang;
            line-height: 0.36rem;
          }

          .text_6 {
            margin-left: 0.06rem;
            margin-top: 0.26rem;
            color: rgb(1, 1, 1);
            line-height: 0.37rem;
          }

          .text_7 {
            margin-top: 0.42rem;
            color: rgb(0, 0, 0);
            letter-spacing: 0.015rem;
          }

          .text-wrapper {
            padding: 0.12rem 0;
            border-radius: 0.21rem;
            width: 1.53rem;
            border: solid 0.01rem rgb(255, 63, 61);
            position: relative;
            margin-top: 0.62rem;
            cursor: pointer;

            .pos {
              transform: translateY(0.5rem);
              opacity: 0;
              pointer-events: none;
              transition: all 0.5s ease;
            }

            @media (orientation: landscape) {
              &:hover {
                .pos {
                  transform: none;
                  opacity: 1;
                  pointer-events: all;
                }
              }
            }

            .text_8 {
              color: rgb(255, 63, 61);
              font-size: 0.2rem;
              font-family: PingFangSC;
              line-height: 0.2rem;
            }
          }
        }

        .space-y-61 {
          & > *:not(:first-child) {
            margin-top: 0.61rem;
          }
        }

        .group_4 {
          margin-left: 0.4rem;

          .image_2 {
            width: 9.06rem;
            height: 5.68rem;
          }
        }

        .space-x-137 {
          & > *:not(:first-child) {
            margin-left: 1.37rem;
          }
        }
      }
    }

    .group_6 {
      background-color: rgb(255, 248, 248);

      .section_3 {
        padding: 0.76rem 0.2rem 1.13rem 0.2rem;
        width: 13.35rem;

        margin: 0 auto;
        max-width: 100%;

        .space-y-12 {
          & > *:not(:first-child) {
            margin-top: 0.12rem;
          }

          .text_10 {
            color: rgb(255, 63, 61);
            font-size: 0.35rem;
          }

          .text_11 {
            color: rgb(51, 51, 51);
            line-height: 0.31rem;
          }

          .text_13 {
            color: rgb(255, 71, 28);
            line-height: 0.11rem;
          }

          .space-x-42 {
            & > *:not(:first-child) {
              margin-left: 0.42rem;
            }
          }
        }

        .text_12 {
          margin-top: 0.69rem;
          color: rgb(85, 85, 85);
          font-size: 0.2rem;
          font-family: Adobe Heiti Std;
          line-height: 0.36rem;
        }

        .group_7 {
          margin-top: 0.79rem;

          .group_8 {
            padding-top: 1.16rem;
            width: 3.42rem;

            .section_4 {
              padding: 0.6rem 0.31rem 0.25rem;
              background-image: url('../../assets/800a387330c3ffede171b34d04be00b1.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 3.42rem;

              .space-x-135 {
                & > *:not(:first-child) {
                  margin-left: 1.35rem;
                }

                .image_9 {
                  width: 0.38rem;
                  height: 0.38rem;
                }
              }
            }

            &:nth-child(1) {
              .section_5 {
                padding-left: 0.32rem;
                padding-right: 0.52rem;

                .space-x-22 > *:not(:first-child) {
                  margin-left: 0.45rem;
                }
              }
            }

            &:nth-child(3) {
              .section_5 {
                padding-left: 0.25rem;
                padding-right: 0.21rem;

                .space-x-22 > *:not(:first-child) {
                  margin-left: 0.17rem;
                }
              }
            }

            .image_6 {
              width: 3.42rem;
              height: 1.53rem;
            }

            .pos_2 {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
            }

            .section_5 {
              padding: 0.59rem 0.48rem 0.33rem;
              background-image: url('../../assets/5954626c2ef82d63a03dc2c75789304a.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;

              .group_9 {
                line-height: 0.15rem;

                .text_14 {
                  line-height: 0.11rem;
                  text-transform: uppercase;
                  word-break: break-all;
                }

                .text_15 {
                  line-height: 0.15rem;
                  word-break: break-all;
                }
              }

              .group_11 {
                margin-right: 0.04rem;
                margin-top: 0.09rem;

                .view_5 {
                  line-height: 0.3rem;
                }

                .view_4 {
                  line-height: 0.3rem;
                }
              }

              .space-x-22 {
                & > *:not(:first-child) {
                  margin-left: 0.22rem;
                }
              }

              .group_12 {
                margin-top: 0.39rem;
                padding: 0 0.06rem;
                justify-content: space-between;

                img {
                  height: 0.37rem;
                }
              }

              .space-x-122 {
                & > *:not(:first-child) {
                  margin-left: 1.22rem;
                }
              }
            }

            .pos_3 {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
            }

            .section_6 {
              padding: 0.6rem 0.22rem 0.34rem 0.25rem;
              background-image: url('../../assets/5954626c2ef82d63a03dc2c75789304a.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;

              .space-y-11 {
                & > *:not(:first-child) {
                  margin-top: 0.11rem;
                }

                .text_16 {
                  line-height: 0.11rem;
                }

                .space-x-19 {
                  & > *:not(:first-child) {
                    margin-left: 0.19rem;
                  }
                }
              }

              .space-x-174 {
                & > *:not(:first-child) {
                  margin-left: 1.74rem;
                }

                .image_8 {
                  width: 0.34rem;
                  height: 0.34rem;
                }

                .text_18 {
                  color: rgb(53, 53, 53);
                }
              }
            }

            .space-y-43 {
              & > *:not(:first-child) {
                margin-top: 0.43rem;
              }
            }

            .pos_4 {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
            }
          }

          .view {
            margin-right: 0.06rem;
          }
        }

        .font_7 {
          font-size: 0.14rem;
          font-family: PingFang;
          line-height: 0.12rem;
          color: rgb(255, 63, 61);
        }

        .view_2 {
          line-height: 0.3rem;
        }

        .view_3 {
          line-height: 0.3rem;
        }
      }
    }

    .section_2 {
      padding: 0.25rem 0.15rem 0.17rem;
      filter: drop-shadow(0px 0.01rem 0.06rem #00000026);
      background-image: url('../../assets/caf5e7861bb2872d53aaf0984071daf4.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 1.52rem;

      .image-wrapper {
        padding: 0.09rem 0;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0.01rem 0.06rem rgba(0, 0, 0, 0.15);
        border-radius: 0.1rem;

        .image_5 {
          width: 1.06rem;
          height: 1.04rem;
        }
      }

      .text_9 {
        color: rgb(51, 51, 51);
        font-size: 0.12rem;
        font-family: PingFangSC;
        line-height: 0.12rem;
      }
    }

    .space-y-16 {
      & > *:not(:first-child) {
        margin-top: 0.16rem;
      }
    }

    .pos {
      position: absolute;
      left: 0;
      top: 100%;
    }

    .section_7 {
      padding: 0.8rem 0 0.18rem 0;
      background-image: url('../../assets/acf80cb4b5bb986344bec088299b3809.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      > div {
        margin: 0 auto;
        width: 10.94rem;
      }

      .group_13 {
        margin-top: 0.82rem;

        .text_19 {
          color: rgb(255, 255, 255);
          font-size: 0.62rem;
          font-family: AkzidenzGroteskBQ;
          line-height: 0.45rem;
        }

        .text_20 {
          margin-top: 0.24rem;
          color: rgb(255, 255, 255);
          line-height: 0.31rem;
        }

        .text_21 {
          margin-top: 0.42rem;
          color: rgb(255, 255, 255);
        }
      }

      .image_10 {
        width: 4.14rem;
        height: 3.75rem;
      }
    }

    .group_14 {
      margin-top: 0.83rem;
      width: 11.5rem;
      max-width: 100%;
      padding: 0 0.45rem;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: 25%;
        padding-right: 0.1rem;
        margin-bottom: 0.27rem;
        display: flex;
        flex-direction: column;

        @media screen and (orientation: landscape) {
          &:nth-child(4n) {
            width: 1.6rem;
            padding-right: 0;
          }
        }

        .image_11 {
          width: 0.77rem;
          height: 0.77rem;
          display: block;
          margin-bottom: 0.26rem;
        }

        .font_10 {
          font-size: 0.2rem;
          line-height: 1;
          font-weight: 500;
          color: #333333;
        }

        .group_10 {
          margin-top: 0.19rem;
          font-size: 0.14rem;
          line-height: 1.7142857;
          color: #555555;

          p {
            display: block;
            margin: 0;
          }
        }
      }
    }

    .font_10 {
      font-size: 0.2rem;
      font-family: PingFang;
      line-height: 0.2rem;
      color: rgb(51, 51, 51);
    }

    .group_10 {
      .font_8 {
        font-size: 0.18rem;
        font-family: Adobe Heiti Std;
        line-height: 0.3rem;
        color: rgb(85, 85, 85);
        display: inherit;
      }

      .text_17 {
        letter-spacing: -0.018rem;
      }
    }

    .font_12 {
      font-size: 0.14rem;
      font-family: PingFang;
      line-height: 0.24rem;
      color: rgb(85, 85, 85);
      display: block;
    }

    .section_8 {
      margin-top: 0.67rem;
      padding: 1.24rem 0;
      background-color: rgb(246, 246, 246);

      .ben_con {
        max-width: 100%;
        padding: 0 0.45rem;
        width: 13.69rem;
        margin: 0 auto;
      }

      .text_28 {
        color: rgb(233, 71, 63);
        display: block;
      }

      .text_29 {
        margin-top: 0.14rem;
        line-height: 0.31rem;
        display: block;
      }

      .view_15 {
        margin-top: 0.06rem;
        width: 100%;

        .space-x-18 {
          width: 5.87rem;
        }
      }

      .space-x-90 {
        & > *:not(:first-child) {
          margin-left: 7.0588%;
        }
      }
    }

    .font_5 {
      font-size: 0.36rem;
      font-family: PingFang;
      line-height: 0.33rem;
    }

    .font_6 {
      font-size: 0.32rem;
      font-family: PingFang;
      line-height: 0.3rem;
      color: rgb(0, 0, 0);
    }

    .group_15 {
      padding: 0 0.02rem;

      .image_12 {
        margin-left: 2.21rem;
      }

      .image_13 {
        margin-left: 2.28rem;
      }

      .image_14 {
        margin-right: 0.49rem;
      }

      .space-x-160 {
        & > *:not(:first-child) {
          margin-left: 1.6rem;
        }
      }

      .text_24 {
        margin-right: 0.12rem;
      }

      .space-x-18 {
        & > *:not(:first-child) {
          margin-left: 0.18rem;
        }

        .group_36 {
          margin-top: 0.12rem;

          .image_18 {
            width: 0.09rem;
            height: 0.38rem;
          }

          .image_19 {
            width: 0.09rem;
            height: 0.09rem;
          }
        }

        .space-y-20 {
          & > *:not(:first-child) {
            margin-top: 0.2rem;
          }
        }

        .group_35 {
          line-height: 0.3rem;
          height: 2.7rem;
          margin-left: 0;

          .font_13 {
            font-size: 0.16rem;
            font-family: PingFang;
            line-height: 0.3rem;
            color: rgb(85, 85, 85);
            display: block;
            padding-left: 0.27rem;
            position: relative;

            img {
              width: 0.09rem;
              display: block;
              position: absolute;
              left: 0;
              top: 0.15rem;
              margin-top: -0.045rem;
            }
          }
        }
      }

      .image_17 {
        width: 42.9%;
      }
    }

    .group_37 {
      margin-top: 0.89rem;
      width: 11.12rem;
      padding-bottom: 0.83rem;

      .space-y-13 {
        & > *:not(:first-child) {
          margin-top: 0.13rem;
        }

        .text_30 {
          color: rgb(255, 63, 61);
        }

        .text_31 {
          margin-right: 0.09rem;
          line-height: 0.31rem;
        }

        .group_40 {
          line-height: 0.18rem;
          height: 0.55rem;
        }
      }

      .xt_con {
        flex-wrap: wrap;
        flex-direction: row;

        > div {
          margin-bottom: 0.35rem;

          @media screen and (orientation: landscape) {
            &:nth-child(odd) {
              width: 4.95rem;
            }

            &:nth-child(even) {
              width: calc(100% - 4.95rem);
            }
          }

          display: block;

          > img {
            height: 0.78rem;
            margin-bottom: 0.33rem;
            display: block;
          }

          .font_9 {
            display: block;
            text-align: left;
          }

          .group_40 {
            margin-top: 0.1rem;
          }
        }
      }

      .group_41 {
        margin-top: 0.37rem;
        padding: 0 0.18rem;

        .image_22 {
          width: 0.77rem;
          height: 0.88rem;
        }

        .image_23 {
          margin: 0.04rem 0 0.04rem 3.99rem;
          width: 0.83rem;
          height: 0.8rem;
        }
      }

      .group_42 {
        margin-top: 0.33rem;

        .text_33 {
          line-height: 0.21rem;
        }
      }

      .space-x-328 {
        & > *:not(:first-child) {
          margin-left: 3.28rem;
        }
      }

      .group_43 {
        margin-top: 0.12rem;
        width: 8.44rem;

        .group_44 {
          line-height: 0.18rem;
          height: 0.53rem;
        }
      }

      .group_45 {
        margin-top: 0.41rem;
        padding: 0 0.11rem;

        .image_24 {
          width: 0.69rem;
          height: 0.69rem;
        }
      }

      .space-x-423 {
        & > *:not(:first-child) {
          margin-left: 4.23rem;
        }
      }
    }

    .space-y-69 {
      & > *:not(:first-child) {
        margin-top: 0.69rem;
      }
    }

    .font_11 {
      font-size: 0.36rem;
      font-family: PingFang;
      line-height: 0.34rem;
    }

    .font_9 {
      font-size: 0.2rem;
      font-family: PingFang;
      line-height: 0.2rem;
      color: rgb(56, 56, 56);
    }

    .group_46 {
      padding: 0.22rem 4.16rem 6.01rem;
      height: 6.9rem;

      .pos_7 {
        position: absolute;
        left: 4.19rem;
        top: 0.22rem;
      }

      .text_34 {
        line-height: 0.21rem;
      }

      .pos_8 {
        position: absolute;
        right: 8.7rem;
        top: 0.22rem;
      }

      .group_48 {
        line-height: 0.18rem;
      }

      .pos_9 {
        position: absolute;
        left: 4.16rem;
        top: 0.54rem;
      }

      .group_49 {
        line-height: 0.18rem;
      }

      .pos_10 {
        position: absolute;
        right: 6.07rem;
        top: 0.53rem;
      }
    }

    .font_14 {
      font-size: 0.14rem;
      font-family: PingFang;
      line-height: 1.2857;
      color: rgb(85, 85, 85);
      display: block;
    }

    .space-y-17 {
      & > *:not(:first-child) {
        margin-top: 0.17rem;
      }
    }
  }

  .space-x-7 {
    & > *:not(:first-child) {
      margin-left: 0.07rem;
    }

    .font_2 {
      font-size: 0.2rem;
      font-family: PingFangSC;
      color: rgb(62, 58, 57);
    }

    .text_4 {
      line-height: 0.16rem;
    }

    .text_3 {
      line-height: 0.2rem;
    }

    .image_27 {
      width: 0.97rem;
      height: 0.97rem;
    }
  }

  @media screen and (orientation: portrait) {
    .space-y-5 {
      .group_3 {
        .section {
          .group_5 {
            .text-wrapper {
              display: none;
            }
          }

          .lb_box {
            .swiper-container {
              .swiper-button-prev,
              .swiper-button-next {
                display: none;
              }
            }

            .swiper-slide {
              > div {
                padding: 0;

                .ban_tex {
                  padding-left: 0.56rem;
                  padding-top: 0.75rem;
                  justify-content: start;
                  line-height: 1;

                  .text_5 {
                    font-size: 0.71rem;
                    font-weight: 700;
                    margin-bottom: 0.26rem;
                  }

                  .text_6 {
                    font-weight: 700;
                    font-size: 0.38rem;
                    margin-left: 0;
                    margin-top: 0;
                  }

                  .text_7 {
                    margin-top: 0.47rem;
                    font-size: 0.32rem;
                  }
                }
              }

              .pub_img_box {
                width: 50.2666%;
                margin-left: auto;

                &::before {
                  margin-top: 92.3076923%;
                }
              }
            }
          }
        }
      }

      .group_6 {
        .section_3 {
          padding: 0.44rem 0.36rem 0.54rem;

          .text_12 {
            margin-top: 0.37rem;
            font-weight: 300;
            font-size: 0.24rem;
            line-height: 1.5;
          }

          .group_7 {
            margin-top: 0.69rem;
            display: block;

            .group_8 {
              width: 100%;
              margin-bottom: 0.3rem;
              padding-top: 3rem;

              &:last-child {
                margin-bottom: 0;
              }

              .section_5 {
                height: 3.98rem;
                padding: 0.3rem 0.4rem 0.4rem !important;

                .font_7 {
                  font-size: 0.24rem;
                  line-height: 1;
                }

                .group_11 {
                  margin-top: 0.26rem;

                  .view_5,
                  .view_4 {
                    width: 50%;
                    margin-left: 0 !important;

                    .font_8 {
                      font-size: 0.26rem;
                      line-height: 1.76923;
                    }
                  }
                }

                .group_12 {
                  margin-top: 0.55rem;
                }

                .group_12 {
                  img {
                    height: 0.48rem;
                  }
                }
              }

              .image_6 {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    .space-y-5 {
      .font_9 {
        margin-right: 0.4rem;
        font-size: 0.26rem;
      }

      .section_7 {
        padding: 0.87rem 0.68rem 0.82rem 0.74rem;

        > div {
          display: block;
          width: 100%;
        }

        .group_13 {
          margin-top: 0;
        }

        .image_10 {
          margin-top: 0.79rem;
          margin-left: auto;
          display: block;
        }
      }

      .group_14 {
        padding: 0.24rem 0.3rem;
        margin-top: 0;
        background-color: rgba(249, 249, 249, 1);

        > div {
          width: 100%;
          margin-bottom: 0.24rem;
          background-color: #fff;
          padding: .3rem .6rem .2rem .32rem;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          .image_11 {
            width: 1.6rem;
            height: 1.6rem;
            margin-bottom: 0;
            position: absolute;
            right: .6rem;
            top: 50%;
            transform: translateY(-50%);
          }

          .font_10 {
            font-size: 0.28rem;
            font-weight: 700;
            color: rgba(255, 63, 61, 1);
          }

          .group_10 {
            margin-top: 0.15rem;

            .font_12 {
              font-size: 0.24rem;
              line-height: 1.916666;
            }
          }
        }
      }

      .section_8 {
        margin-top: 0;
        padding: 0.7rem 0.38rem 0.55rem;

        .ben_con {
          padding: 0;

          .view_15 {
            margin-top: 0.35rem;
            display: block;

            .space-x-18 {
              width: 100%;
              display: block;

              .group_35 {
                height: auto;

                .font_13 {
                  font-size: 0.24rem;
                  line-height: 1.6666;
                  color: #555555;
                  width: 100%;
                  padding: 0;
                  display: block;
                }
              }
            }

            .image_17 {
              margin-left: 0;
              width: 100%;
              margin-top: 0.33rem;
              display: block;
            }
          }
        }
      }

      .group_37 {
        width: 100%;
        padding: 0.68rem 0.2rem 1rem 0.4rem;
        margin-top: 0;

        .space-y-13 {
          padding-left: 0.28rem;
        }
        .xt_con {
          justify-content: space-between;

          > div {
            margin-bottom: 0.38rem;
            width: 47.826%;
            > img {
              height: 0.78rem;
              margin-bottom: 0.39rem;
            }

            .font_9 {
              font-size: 0.28rem;
              font-weight: 700;
            }

            .group_40 {
              margin-top: 0.19rem;
              .font_14 {
                font-size: 0.24rem;
                line-height: 1.66666;
                br {
                  display: none;
                }
              }
            }
          }
        }
      }

      .space-y-69 > *:not(:first-child) {
        margin-top: 0.88rem;
      }
    }
  }
}
</style>