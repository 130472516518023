<template>
  <div class="pub-flex-col page">
    <nav-box></nav-box>
    <div class="pub-flex-col">
      <div class="pub-flex-row pub-justify-between section_001">
        <div
          class="
            pub-flex-row pub-items-start pub-self-start
            group_3
            space-x-177
          "
        >
          <div class="pub-flex-col pub-items-start space-y-31">
            <span class="text_5">About us</span>
            <span class="text_6">大美云集帮您直连赛事</span>
          </div>
        </div>
        <div
          class="pub-flex-col pub-justify-start pub-items-end image-wrapper"
        ></div>
      </div>

      <div
        class="
          pub-flex-row pub-justify-between pub-items-start pub-self-center
          group_4
        "
      >
        <div class="pub-flex-col space-y-115">
          <div class="pub-flex-col pub-items-start group_5 space-y-12">
            <span class="font_4 text_7">System introduction</span>
            <span class="font_5 text_8">企业简介</span>
          </div>
          <img
            class="image_5"
            src="../../assets/ce21320f8fe6a5b6bffb5b47b99e63e6.png"
          />
        </div>
        <div class="group_6">
          <p class="font_6">
            厦门大美云集文化科技是一家以文化行业SAAS系统作为落地工具为学会、协会，商会，美育类机构及教师提供IP包装、策划、运营、落地执行一条龙服务的公司。大美云集将比赛、考级、网校、官网、云展融入企业线上平台、以平台为核心工具，实施企业线上运营和行业平台定制开发。
            <br /><br />
            大美云集拥有一支具有多家知名大型企业、资深文化行业工作经历的高素质人才队伍，骨干成员均具有多年的文化行业不同业务岗位运营管理经验，在文化行业运营和线上平台建设的融合及实际应用方面有深入研究、并有着丰富实际研发经验，为用户提供精益求精、简洁高效的高品质产品和服务。
            <br /><br />
            不同于传统营销咨询公司“只营销，不落地”的尴尬，由于具备企业多位资深专家多年实战经验结合、并以自行研发的文化行业SAAS平台作为企业IP运营和线上平台搭建的落地工具，大美云集所导入的运营理念均能透过大美云集比赛、考级、网校、官网、云展SAAS系统在贵公司的使用而迅速落地，线上运营也不再局限于线下，而能够持续的帮助贵司改善业绩、提升管理绩效，达到“天天有对接、月月有签约、季季有开工”的效果。
          </p>
        </div>
      </div>
      <div class="pub-flex-row section_2 space-x-114">
        <div
          class="pub-flex-col pub-justify-start pub-items-start image-wrapper_2"
        >
          <img
            class="image_6 pc_sty"
            src="../../assets/02e6e99add3d43455991a92cc8c22052.png"
          />
          <img class="image_6 m_sty" src="../../assets/logo_m.png" />
        </div>
        <div class="pub-flex-col pub-self-start group_7 space-y-36">
          <div class="pub-flex-col pub-items-end group_8 space-y-12">
            <span class="font_4 text_9">Corporate culture</span>
            <span class="font_5 text_10">企业文化</span>
          </div>
          <span class="pub-self-start font_7 text_11"
            >探索极致用户体验与最佳工程实践</span
          >
        </div>
      </div>
      <div class="pub-flex-col pub-self-center group_9 space-y-43">
        <div class="pub-flex-row">
          <div class="abo_con">
            <img src="../../assets/2eafc0908af82b2bceace89f42c0597a.png" />
            <span class="font_8">Enterprise mission</span>
            <span class="font_9">企业使命</span>
            <span class="font_10">让技术为文化发展赋能</span>
          </div>
          <div class="abo_con">
            <img src="../../assets/c667f9083d29bcdc3ee4e8f997665ce5.png" />
            <span class="font_8">Enterprise vision</span>
            <span class="font_9">企业愿景</span>
            <span class="font_10"
              >成为文化领域最有价值的线上技术综合服务供</span
            >
          </div>
          <div class="abo_con">
            <img src="../../assets/4b65e2e133fda3671fc0f7966fcdb2f8.png" />
            <span class="font_8">Enterprise spirit</span>
            <span class="font_9">企业精神</span>
            <span class="font_10">踏踏实实沟通，认认真真执行</span>
          </div>
        </div>
      </div>
      <div class="pub-flex-col group_12">
        <div class="pub-flex-col section_3">
          <div class="abo_3_con">
            <div class="pub-flex-row pub-items-start space-x-95">
              <div class="pub-flex-col pub-flex-auto space-y-21">
                <div class="pub-flex-col pub-items-end space-y-14">
                  <span class="font_4 text_16">Honor and qualification</span>
                  <span class="font_5 text_17">荣誉资质</span>
                </div>
                <img
                  class="pub-self-start image_9"
                  src="../../assets/35cf7fe5bd3cab6e914503d05db16f02.png"
                />
              </div>

              <div class="pub-flex-row group_13 space-x-12">
                <div>
                  <img
                    class="image_8"
                    src="../../assets/0c90f52755c54b253536d990a42a5d3e.png"
                  />
                  <p class="font_11">
                    艺术测评系统国家
                    <br />软件产品认证
                  </p>
                </div>

                <div>
                  <img
                    class="image_8"
                    src="../../assets/8923f2cae657b7ed2fc1eb278138a274.png"
                  />
                  <p class="font_11">
                    人工智能大数据分析管理
                    <br />系统国家软件产品认证
                  </p>
                </div>

                <div>
                  <img
                    class="image_8"
                    src="../../assets/8aba5e7015a6f52db404fecb7dfaf2e3.png"
                  />
                  <p class="font_11">
                    多元化超级评审系统
                    <br />国家软件产品认证
                  </p>
                </div>
              </div>
            </div>

            <div class="group_16">
              <div class="pub-flex-row pub-justify-between">
                <div>
                  <img
                    class="image_8"
                    src="../../assets/fec5ec04a28309bf918d722dad4207c8.png"
                  />
                  <p class="font_10">活动多维度报名系统</p>
                </div>
                <div>
                  <img
                    class="image_8"
                    src="../../assets/30465cb77522b4b8cc961cf5ce9e024a.png"
                  />
                  <p class="font_10">精准招生检索系统</p>
                </div>
                <div>
                  <img
                    class="image_8"
                    src="../../assets/9910d3e6285aa165349a46ce46424b77.png"
                  />
                  <p class="font_10">比赛活动SAAS系统</p>
                </div>
                <div>
                  <img
                    class="image_10"
                    src="../../assets/9bbc067909125f5ec1723b16f7e8fbe0.png"
                  />
                  <p class="font_11">
                    中华人民共和国增值
                    <br />
                    电信业务经营许可证
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from '../../components/navbox/navbox.vue'
import FootBox from '../../components/footbox/footbox.vue'
export default {
  components: { NavBox, FootBox },
  data() {
    return {
      Version:2
    }
  },
  created() {
			if(this.$wechat.isWeixin()){
				let shareObj = {
					
          title: this.Version == 1 ? '赛事系统' : '赛事管理系统', // 分享标题
							desc: this.Version == 1 ? '集技术的先进性、管理的有效性于一体的高效管理信息系统。' :
								'大美云集学会/协会/商会/企业赛事活动系统简介', // 分享描述
							link: location.href, // 分享链接，该链接必须与当前页面路径一致
							imgUrl: window.location.origin + '/static/img/shareLogo.jpg', // 分享图标

				}
				this.$wechat.wxShare(shareObj)
			}
      
  },
  mounted(){
   
  },

  methods: {

  },
}
</script>

<style scoped lang="scss">
.page {
  background-color: rgb(255, 255, 255);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .group {
    padding: 0.53rem 3.05rem 0.48rem 3.2rem;

    .image {
      width: 2.12rem;
      height: 0.56rem;
    }

    .group_2 {
      margin-top: 0.32rem;

      .space-x-4 {
        & > *:not(:first-child) {
          margin-left: 0.04rem;
        }

        .font_3 {
          font-size: 0.2rem;
          font-family: PingFang;
          line-height: 0.16rem;
          color: rgb(62, 58, 57);
        }

        .font_1 {
          font-size: 0.2rem;
          font-family: PingFang;
          line-height: 0.18rem;
          color: rgb(62, 58, 57);
        }

        .text {
          line-height: 0.19rem;
        }

        .text_2 {
          line-height: 0.19rem;
        }
      }
    }

    .space-x-66 {
      & > *:not(:first-child) {
        margin-left: 0.66rem;
      }
    }
  }

  .group_4 {
    margin-top: 0.93rem;
    width: 13.36rem;
    max-width: 100%;

    .space-y-115 {
      & > *:not(:first-child) {
        margin-top: 1.15rem;
      }

      .group_5 {
        padding: 0 0.61rem;

        .text_7 {
          color: rgb(255, 63, 61);
          font-size: 0.35rem;
        }

        .text_8 {
          color: rgb(51, 51, 51);
          line-height: 0.31rem;
        }
      }

      .image_5 {
        width: 5.26rem;
        height: 4.39rem;
      }
    }

    .group_6 {
      margin-top: 0.79rem;
      line-height: 0.36rem;
      width: 5.92rem;
      height: 5.76rem;

      .font_6 {
        font-size: 0.16rem;
        font-family: Adobe Heiti Std;
        line-height: 0.36rem;
        color: rgb(85, 85, 85);
      }
    }
  }

  .section_2 {
    margin-top: 1.35rem;
    padding-bottom: 0.04rem;
    // background-image: url('../../assets/dbf84354e9e8637bd994cbafdf57aeaf.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    background-color: #f6f6f6;

    &::after {
      content: '';
      display: block;
      width: 43.02%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      height: 100%;
      background-color: #ff3f3d;
    }

    .image-wrapper_2 {
      padding: 2.31rem 0 1.18rem;
      background-image: url('../../assets/3ecc151f03060cf29c0bb0bf9c7036b0.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 10.94rem;
      max-width: 100%;
      height: 4.69rem;

      .image_6 {
        margin-left: 3.6rem;
        width: 2.43rem;
        height: 1.2rem;
      }
    }

    .group_7 {
      margin-top: 1.56rem;
      position: absolute;
      z-index: 2;
      right: calc(50% - 6.4rem);
      top: 0;

      .group_8 {
        padding-left: 0.86rem;

        .text_9 {
          color: rgb(255, 255, 255);
        }

        .text_10 {
          margin-right: 0.13rem;
          color: rgb(255, 255, 255);
          line-height: 0.31rem;
        }
      }

      .text_11 {
        letter-spacing: -0.0048rem;
      }
    }

    .space-y-36 {
      & > *:not(:first-child) {
        margin-top: 0.36rem;
      }
    }
  }

  .space-x-114 {
    & > *:not(:first-child) {
      margin-left: 1.14rem;
    }
  }

  .space-y-12 {
    & > *:not(:first-child) {
      margin-top: 0.12rem;
    }
  }

  .font_4 {
    font-size: 0.36rem;
    font-family: PingFang;
    line-height: 0.33rem;
  }

  .font_5 {
    font-size: 0.32rem;
    font-family: PingFang;
    line-height: 0.3rem;
  }

  .group_9 {
    margin-top: 1.65rem;
    width: 12.54rem;
    max-width: 100%;

    .image_7 {
      width: 2.74rem;
      height: 3.35rem;
    }

    .pos {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .pos_2 {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .group_10 {
      padding: 0 0.17rem;

      .text_12 {
        margin-left: 2.59rem;
        font-size: 0.25rem;
      }

      .text_13 {
        margin-left: 3rem;
        font-size: 0.25rem;
      }

      .space-x-379 {
        & > *:not(:first-child) {
          margin-left: 3.79rem;
        }
      }

      .font_9 {
        font-size: 0.24rem;
        font-family: PingFang;
        line-height: 0.23rem;
        color: rgb(0, 0, 0);
      }

      .text_14 {
        margin-right: 1.51rem;
      }

      .group_11 {
        margin-right: 0.62rem;

        .text_15 {
          line-height: 0.15rem;
        }
      }

      .space-x-217 {
        & > *:not(:first-child) {
          margin-left: 2.17rem;
        }
      }
    }
  }

  .space-y-43 {
    & > *:not(:first-child) {
      margin-top: 0.43rem;
    }

    .pub-flex-row {
      justify-content: space-between;
    }

    .abo_con {
      width: 2.8rem;

      > img {
        width: 2.74rem;
        display: block;
      }

      span {
        display: block;
      }

      .font_8 {
        margin-top: 0.47rem;
      }

      .font_9 {
        margin-top: 0.19rem;
        font-size: 0.24rem;
        font-family: PingFang;
        font-weight: 400;
        line-height: 1;
      }

      .font_10 {
        margin-top: 0.15rem;
      }
    }
  }

  .group_12 {
    margin-top: 1.69rem;

    .section_3 {
      padding: 1.38rem 3.31rem 1.1rem 3.68rem;
      background-image: url('../../assets/a4ef70eb3457ebe3efe2849ecf0b7315.png');
      background-size: 100% 100%;

      .abo_3_con {
        .space-x-95 {
          flex-direction: row-reverse;

          .space-x-12 {
            padding-top: 0.26rem;
            text-align: center;
          }
        }

        .space-y-14 {
          text-align: right;
        }
      }

      .space-x-95 {
        .space-y-21 {
          margin-left: 0.95rem;
        }

        .group_13 {
          margin-bottom: 0.06rem;
        }

        .space-x-12 {
          & > *:not(:first-child) {
            margin-left: 0.12rem;
          }
        }

        .space-y-21 {
          & > *:not(:first-child) {
            margin-top: 0.21rem;
          }

          .space-y-14 {
            & > *:not(:first-child) {
              margin-top: 0.14rem;
            }

            .text_16 {
              color: rgb(1, 1, 1);
            }

            .text_17 {
              margin-right: 0.38rem;
              color: rgb(0, 0, 0);
              line-height: 0.31rem;
            }
          }

          .image_9 {
            width: 1.57rem;
            height: 1.32rem;
          }
        }
      }

      .group_14 {
        margin-left: 0.2rem;
        margin-top: 0.06rem;
        width: 4.44rem;

        .group_15 {
          line-height: 0.18rem;
          text-align: center;
          height: 0.36rem;
        }
      }

      .space-x-24 {
        & > *:not(:first-child) {
          margin-left: 0.24rem;
        }
      }

      .group_16 {
        margin-right: 1.08rem;
        margin-top: 0.36rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 0.89rem;
        text-align: center;

        .pub-justify-between {
          width: 6.29rem;
        }

        .image_10 {
          width: 1.39rem;
          height: 1.99rem;
        }

        .group_17 {
          margin: 0.12rem 0.03rem 0 0.12rem;

          .group_18 {
            line-height: 0.18rem;
            text-align: center;
            height: 0.35rem;
          }
        }
      }

      .image_8 {
        width: 1.49rem;
        height: 1.99rem;
      }

      .font_11 {
        font-size: 0.14rem;
        font-family: PingFang;
        line-height: 1.2857;
        color: rgb(85, 85, 85);
      }
    }

    .section_4 {
      padding: 0.85rem 0 1.1rem;
      background-color: rgb(46, 46, 46);

      .group_19 {
        margin-left: 5.28rem;

        .text_18 {
          color: rgb(255, 63, 62);
          font-size: 0.36rem;
          font-family: AkzidenzGroteskBQ;
          line-height: 0.26rem;
        }

        .text_19 {
          color: rgb(253, 248, 247);
        }
      }

      .group_20 {
        width: 8.88rem;

        .space-y-10 {
          & > *:not(:first-child) {
            margin-top: 0.1rem;
          }

          .image_11 {
            width: 1.06rem;
            height: 0.68rem;
          }

          .image_13 {
            width: 0.95rem;
            height: 0.34rem;
          }

          .text_23 {
            font-size: 0.17rem;
            line-height: 0.12rem;
          }

          .text_24 {
            color: rgb(255, 255, 255);
            font-size: 0.29rem;
            font-family: DINNextLTPro;
            font-weight: 500;
            line-height: 0.21rem;
            letter-spacing: -0.012rem;
          }

          .text_26 {
            line-height: 0.17rem;
          }

          .text_27 {
            line-height: 0.16rem;
            letter-spacing: -0.0064rem;
            text-transform: uppercase;
            opacity: 0.8;
          }
        }

        .group_21 {
          margin-left: 0.72rem;
          margin-top: 0.09rem;
          width: 1.2rem;

          .text_21 {
            line-height: 0.24rem;
          }

          .group_24 {
            line-height: 0.3rem;

            .text_25 {
              opacity: 0.41;
            }
          }
        }

        .space-y-15 {
          & > *:not(:first-child) {
            margin-top: 0.15rem;
          }
        }

        .group_22 {
          margin-left: 0.8rem;
          margin-top: 0.11rem;
          width: 5.1rem;

          .space-y-7 {
            & > *:not(:first-child) {
              margin-top: 0.07rem;
            }

            .group_25 {
              padding: 0 0.2rem;

              .font_13 {
                font-size: 0.12rem;
                font-family: Source Han Sans CN;
                line-height: 0.12rem;
                font-weight: 300;
                color: rgb(255, 255, 255);
              }
            }

            .space-x-41 {
              & > *:not(:first-child) {
                margin-left: 0.41rem;
              }
            }
          }

          .group_23 {
            margin-bottom: 0.04rem;

            .space-x-16 {
              & > *:not(:first-child) {
                margin-left: 0.16rem;
              }

              .image_14 {
                margin-top: 0.03rem;
                width: 0.14rem;
                height: 0.41rem;
              }
            }
          }

          .space-y-23 {
            & > *:not(:first-child) {
              margin-top: 0.23rem;
            }
          }

          .text_22 {
            text-transform: uppercase;
          }
        }

        .font_12 {
          font-size: 0.16rem;
          font-family: PingFang;
          line-height: 0.3rem;
          color: rgb(255, 255, 255);
        }

        .text_20 {
          opacity: 0.8;
        }
      }
    }

    .space-y-72 {
      & > *:not(:first-child) {
        margin-top: 0.72rem;
      }
    }
  }

  .font_10 {
    font-size: 0.14rem;
    font-family: PingFang;
    line-height: 1.27;
    color: rgb(85, 85, 85);
  }

  .space-y-17 {
    & > *:not(:first-child) {
      margin-top: 0.17rem;
    }
  }

  .font_8 {
    font-size: 0.24rem;
    font-family: PingFang;
    line-height: 0.23rem;
    color: rgb(255, 63, 62);
  }

  .font_7 {
    font-size: 0.24rem;
    font-family: Source Han Sans CN;
    line-height: 0.23rem;
    font-weight: 300;
    color: rgb(255, 255, 255);
  }

  .space-x-7 {
    & > *:not(:first-child) {
      margin-left: 0.07rem;
    }

    .font_2 {
      font-size: 0.2rem;
      font-family: PingFangSC;
      color: rgb(62, 58, 57);
    }

    .text_4 {
      line-height: 0.16rem;
    }

    .text_3 {
      line-height: 0.2rem;
    }

    .image_12 {
      width: 0.97rem;
      height: 0.97rem;
    }
  }

  @media screen and (orientation: portrait) {
    .group_4 {
      padding: 0.52rem 0.3rem 0.45rem;
      margin-top: 0;
      display: block;

      .space-y-115 {
        .group_5 {
          padding: 0 0.18rem;
        }

        .image_5 {
          width: 76.23188%;
          display: block;
          margin: 0.56rem auto 0;
        }
      }

      .group_6 {
        margin-top: 0.45rem;
        width: 100%;
        height: auto;
        .font_6 {
          font-size: 0.24rem;
          line-height: 1.5;
          margin: 0;
        }
      }
    }
    .section_2 {
      margin-top: 0;
      background-color: #ff3f3d;
      &::after {
        display: none;
      }
      height: 3rem;
      padding: 0.1rem 0.65rem 0.1rem 0.82rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .image-wrapper_2 {
        background-image: none;
        width: 1.58rem;
        height: auto;
        padding: 0;
        .image_6 {
          width: 100%;
          height: 0.78rem;
          margin-left: 0;
        }
      }

      .group_7 {
        position: relative;
        right: auto;
        top: auto;
        margin-top: 0;
        align-self: center;
        margin-left: 0;
        .group_8 {
          .text_10 {
            margin-right: 0;
          }
        }
        .font_7 {
          width: 100%;
          text-align: right;
        }
      }
    }
    .group_9 {
      padding: 0.4rem 0.3rem;
      margin-top: 0;
    }
    .space-y-43 {
      .pub-flex-row {
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .abo_con {
        width: 47.83%;
        margin-bottom: 0.35rem;
        &:last-child {
          margin-bottom: 0;
        }
        > img {
          width: 100%;
        }
        .font_8 {
          font-size: 0.28rem;
          margin-top: 0.31rem;
          line-height: 1;
        }
        .font_9 {
          margin-top: 0.24rem;
          line-height: 1;
          font-size: 0.28rem;
          font-weight: 700;
        }
        .font_10 {
          margin-top: 0.12rem;
          font-size: 0.22rem;
          line-height: 1.272727;
        }
      }
    }
    .group_12{
      margin-top: 0;
      .section_3{
        padding: .65rem .2rem .6rem;

        .abo_3_con {
          .space-x-95{
            display: block;

            .space-y-21{
              display: flex;
              margin-left: 0;
              align-items: center;
              flex-direction: row;
            justify-content: flex-end;
            .image_9{
              margin-top: 0;
              margin-left: .4rem;
              width: .98rem;
              height: .82rem;
            }
            .space-y-14 {
              .text_17{
              margin-right: 0;
            }
            }
            }

            .space-x-12{
              padding-top: .6rem;

              justify-content: space-around;
              > div{
                width: 31%;
                margin-left: 0 !important;

                > img{
                  width: 1.87rem;height: auto;
                  display: block;
                  margin: 0 auto;
                }

              }


            }
            
          }
        }

        .font_11{
          font-size: .2rem;
          line-height: 1.2;
          margin-top: .09rem;
        }

        .group_16{
          padding-right: 0;
          margin-right: 0;
          justify-content:space-between;

         .pub-justify-between{
          width: 100%;
          > div{
            width: 25%;
            img{
              width: auto;
              height: 2.18rem;
              display: block;
              margin: 0 auto;
            }
            .font_10,.font_11{
              font-size: .2rem;
          line-height: 1.2;
          margin-top: .09rem;
            }
          }
         }
        }
      }
    }
  }
}
</style>