import Vue from 'vue';
import App from './app.vue';
import router from './router';

import Vant from 'vant';
import 'vant/lib/index.css';

// import VueResource from 'vue-resource'
// Vue.use(VueResource)

Vue.use(Vant);

import axios from 'axios'
// 配置请求的根路径
axios.defaults.baseURL = 'http://admin.dameiyunji.com/'
//axios 拦截器
axios.interceptors.request.use(config =>{
  // console.log(config)
  // config.headers.Authorization = window.sessionStorage.gitItem('token')
  //在最后必须  return config
  return config
})
Vue.prototype.$http = axios;


// HH: 扩展 axios，让 axios 支持 jsonp
// axios.jsonp = (url, linkA) => {
// 	if (!url) {
// 		console.error('Axios.JSONP 至少需要一个url参数!')
// 		return;
// 	}
// 	let callbackName = 'successCallback', linkC = linkA;

// 	return new Promise((resolve, reject) => {
// 		let JSONP = document.createElement("script");
// 		JSONP.type = "text/javascript";
// 		JSONP.src = `${url}?jsonpCallback=${callbackName}&url=${linkC}`;
// 		// document.getElementsByTagName("header")[0].appendChild(JSONP);
// 		document.body.appendChild(JSONP)
// 		window[callbackName] = (res) => {
// 			resolve(res)
// 		}
// 		JSONP.onload = function () {
// 			this.remove() //将script标签删除
// 		}
// 	})
// }
// export const jsonp = (url, index) => axios.jsonp(url, index)




Vue.config.productionTip = false;


import wechat from './utils/wechat.js'
Vue.prototype.$wechat = wechat



new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

