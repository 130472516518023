
<template>
  <footer>
    <div class="pub-flex-col pub-justify-start group_47 pos_5">
      <div class="pub-flex-col section_9 space-y-72">
        <div class="pub-flex-col pub-items-start pub-self-start group_50 space-y-17">
          <span class="text_35">Damei</span>
          <span class="text_36">大美云集赛事管理系统</span>
        </div>
        <div class="pub-flex-row pub-self-center group_51">
          <div class="pub-flex-col pub-items-center pub-shrink-0 pub-self-start space-y-10">
            <img class="image_26" src="../../assets/df91659e94fcfa297c6f840ba39ce61b.png" />
            <img class="image_28" src="../../assets/cf4f8ebfbcb20d1b7fe1a8ed398d2375.png" />
          </div>
          <div class="pub-flex-col pub-shrink-0 group_52 space-y-15">
            <span class="pub-self-start text_37 text_38">合作单位</span>
            <div class="group_55">
              <span class="font_15 text_42">
                活动/比赛组委会
                <br />
              </span>
              <span class="font_15 text_42">
                官方发证单位
                <br />
              </span>
              <span class="font_15 text_42">
                学校
                <br />
              </span>
              <span class="font_15 text_42">
                社团组织
                <br />
              </span>
              <span class="font_15 text_42">商企/事业单位</span>
            </div>
          </div>
          <div class="pub-flex-row pub-justify-between pub-flex-auto pub-self-start group_53">
            <div class="pub-flex-col space-y-7">

              <div class="pub-flex-row space-x-7 pub-justify-around">
                <img class="image_27" src="../../assets/code_002.jpg" />
                <img class="image_27" src="../../assets/code_001.jpg" />
              </div>
              <div class="pub-flex-row group_56 space-x-40  pub-justify-around">
                <span class="font_16 text_39">关注小程序</span>
                <span class="font_16 text_39">关注公众号</span>
              </div>
            </div>
            <div class="pub-flex-col group_54 space-y-23">
              <div class="pub-flex-col pub-items-start space-y-10">
                <span class="text_39 text_40">TEL</span>
                <span class="text_39 text_41">189 6518 5529</span>
              </div>
              <div class="pub-flex-row space-x-16">
                <img class="image_29" src="../../assets/c241b5cf815d52ef0198a0d864d8bccc.png" />
                <div class="pub-flex-col pub-items-center space-y-10">
                  <span class="font_15 text_37 text_43">http://www.dameiyunji.com</span>
                  <span class="font_15 text_44">福建省厦门市思明区会展南路11</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
footer {
  pointer-events: none;

  .group_47 {
    padding-top: 2.06rem;
    margin-top: -2.06rem;

    .section_9 {
      padding: 0.85rem 0 0.63rem;
      background-color: rgb(46, 46, 46);
      pointer-events: all;

      .group_50 {
       
        width: 13.75rem;
        margin: 0 auto;

        .text_35 {
          color: rgb(255, 63, 62);
          font-size: 0.36rem;
          font-family: AkzidenzGroteskBQ;
          line-height: 0.26rem;
        }

        .text_36 {
          color: rgb(253, 248, 247);
          font-size: 0.24rem;
          font-family: PingFang;
          line-height: 0.23rem;
          margin-top: .17rem;
        }
      }

      .group_51 {
        width:13.75rem;
        justify-content: space-between;

        .space-y-10 {
          &>*:not(:first-child) {
            margin-top: 0.1rem;
          }

          .image_26 {
            width: 1.06rem;
            height: 0.68rem;
          }

          .image_28 {
            width: 0.95rem;
            height: 0.34rem;
          }

          .text_40 {
            color: rgb(255, 255, 255);
            font-size: 0.17rem;
            font-family: PingFang;
            line-height: 0.12rem;
          }

          .text_41 {
            color: rgb(255, 255, 255);
            font-size: 0.29rem;
            font-family: DINNextLTPro;
            font-weight: 500;
            line-height: 0.21rem;
            letter-spacing: -0.012rem;
          }

          .text_43 {
            line-height: 0.17rem;
          }

          .text_44 {
            line-height: 0.16rem;
            letter-spacing: -0.0064rem;
            text-transform: uppercase;
            opacity: 0.8;
          }
        }

        .group_52 {
          margin-left: 0.73rem;
          margin-top: 0.09rem;
          width: 3rem;
          min-width: 180px;

          .text_38 {
            color: rgb(255, 255, 255);
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 300;
            line-height: 0.24rem;
          }

          .group_55 {
            line-height: 0.3rem;
            display: flex;
            flex-wrap: wrap;

            .text_42 {
              opacity: 0.41;
              width: 50%;
            }
          }
        }

        .space-y-15 {
          &>*:not(:first-child) {
            margin-top: 0.15rem;
          
            @media screen and (orientation: landscape){
              justify-content: space-between;
            }
          }
        }

        .group_53 {
          margin-left: 0.79rem;
          margin-top: 0.11rem;
         
          // width: 5.1rem;
          @media screen and (orientation: landscape) {
            flex: none;
}

          .space-y-7 {
            margin-right: .3rem;


            &>*:not(:first-child) {
              margin-top: 0.07rem;
            }

            width: 2.05rem;

            img {
              width: .97rem;
            }

            .group_56 {
              width: 100%;

              .font_16 {
                font-size: 0.12rem;
                font-family: Source Han Sans CN;
                line-height: 0.12rem;
                font-weight: 300;
                color: rgb(255, 255, 255);
                width: .97rem;
                text-align: center;
              }
            }


          }

          .group_54 {
            margin-bottom: 0.04rem;

            .space-x-16 {
              &>*:not(:first-child) {
                margin-left: 0.16rem;
              }

              .image_29 {
                margin-top: 0.03rem;
                width: 0.13rem;
                height: 0.41rem;
              }
            }
          }

          .space-y-23 {
            &>*:not(:first-child) {
              margin-top: 0.23rem;
            }
          }

          .text_39 {
            text-transform: uppercase;
          }
        }

        .font_15 {
          font-size: 0.16rem;
          font-family: PingFang;
          line-height: 0.3rem;
          color: rgb(255, 255, 255);
          display: block;
        }

        .text_37 {
          opacity: 0.8;
        }
      }
    }

    .space-y-72 {
      &>*:not(:first-child) {
        margin-top: 0.72rem;
      }
    }

    .image_25 {
      width: 25.41%;
      max-width: 5.88rem;

    }

    .pos_6 {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .pos_5 {
    position: relative;

  }


  @media screen and (orientation: portrait) {
    .group_47 {
      padding-top: 1rem;
      margin-top: -1rem;

      .section_9 {
        padding-left: .4rem;
        padding-right: .4rem;

        .group_50 {
          margin-left: 0;
        }

        .group_51 {
          width: 100%;
          flex-wrap: wrap;

          .group_53 {
            margin-top: .5rem;
            margin-left: 0;
            width: 100%;
             .group_54 .space-x-16 .image_29{
              margin-top: 0;
              height: auto;
              width: .18rem;
            }

            .space-y-23>*:not(:first-child) {
              align-items: center;

            }

            .space-y-7 {
              width: 2.8rem;

              .space-x-7 {
                justify-content: space-between;

                img {
                  width: 1.3rem;
                }
              }

              .group_56 {
                justify-content: space-between;

                .font_16 {
                  font-size: .24rem;
                  line-height: 1.5;
                  width: 1.3rem;
                }
              }

            }


           

          }

          .space-y-10 {
            .text_39 {
              font-size: .24rem;
              line-height: 1;

            }

            .text_41 {
              margin-top: .1rem;
            }
          }

          .font_15 {
            font-size: .24rem;
            line-height: 1.2 !important;
          }
          .group_52{
            width: 4.4rem;

            .group_55{
              display: flex;
              flex-wrap: wrap;
              span{
                width: 50%;
              }

            }

          }



        }

      }

      .image_25{
        width: 35%;
      }
    }
  }

}
</style>