<template>
  <div class="pub-flex-col page">
    <nav-box></nav-box>
    <div class="pub-flex-col">
      <div class="pub-flex-row pub-justify-between section_001">
        <div
          class="
            pub-flex-row pub-items-start pub-self-start
            group_3
            space-x-177
          "
        >
          <div class="pub-flex-col pub-items-start space-y-31">
            <span class="text_5">Real-time info</span>
            <span class="text_6">大美云集帮您直连赛事</span>
          </div>
        </div>
        <div
          class="pub-flex-col pub-justify-start pub-items-end image-wrapper"
        ></div>
      </div>
      <div class="pub-flex-col pub-self-center view_1 space-y-46">
        <div class="pub-flex-col">
          <div class="pub-flex-row pub-items-center pub-self-start tab_box">
            <div
              class="
                pub-flex-col pub-justify-start pub-items-center pub-shrink-0
                text-wrapper
              "
              :class="{on:active == 0}"
              @click="ajaxH(null,0)"
            >
              <span class="font_4 text_7">全部</span>
            </div>

            <div
              class="
                pub-flex-col pub-justify-start pub-items-center pub-shrink-0
                text-wrapper_2
              "
              :key="i"
              v-for="(item, i) in tabList"
              :class="{on:active == i+1}"
              @click="ajaxH(item.id,i+1)"
            >
              <span class="font_5 text_8">{{ item.name }}</span>
            </div>
          </div>
          <div class="pub-flex-row list group_14">
            <div
              class="pub-flex-col section_2 space-y-229"
              :key="i"
              v-for="(item, i) in newsList"
              @click="toDet(item.id)"
            >
              <div class="pub_img_box">
                <img :src="item.image" alt="" />
              </div>

              <div class="new_con_t">
                <div
                  class="pub-flex-col text-wrapper_4"
                  v-if="item.categoryName === '官网动态'"
                >
                  <span class="font_6 text_29">{{ item.categoryName }}</span>
                </div>
                <div class="pub-flex-col text-wrapper_3" v-else>
                  <span class="font_6 text_29">{{ item.categoryName }}</span>
                </div>

                <div class="pub-flex-col pub-items-start space-y-11">
                  <span class="font_7">{{ item.title }}</span>
                  <span class="font_4">{{ item.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <van-pagination
          v-if="pageShow"
          v-model="currentPage"
          :total-items="total"
          :items-per-page="pageN"
          :force-ellipses="true"
          :prev-text="pt"
          :next-text="nt"
          @change="changePage"
        />
      </div>
    </div>
    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from '../../components/navbox/navbox.vue'
import FootBox from '../../components/footbox/footbox.vue'
export default {
  components: { NavBox, FootBox },

  data() {
    return {
      allNewsList: [],
      newsList: [],
      tabList: [],
      active:0,

      currentPage: 1,
      pt: '<',
      nt: '>',
      pageN: 11,
      total: 25,
      pageShow: true,

      Version:2,
    }
  },
  created() {
    this.ajaxH(null,0)
    this.listH();
    if(this.$wechat.isWeixin()){
				let shareObj = {
					
          title: this.Version == 1 ? '赛事系统' : '赛事管理系统', // 分享标题
							desc: this.Version == 1 ? '集技术的先进性、管理的有效性于一体的高效管理信息系统。' :
								'大美云集学会/协会/商会/企业赛事活动系统简介', // 分享描述
							link: location.href, // 分享链接，该链接必须与当前页面路径一致
							imgUrl: window.location.origin + '/static/img/shareLogo.jpg', // 分享图标

				}
				this.$wechat.wxShare(shareObj)
			}
  },
    mounted() {

  },
  methods: {
    changePage(currentindex) {
      // console.log(currentindex)
      this.newsList = this.allNewsList.slice(
        (currentindex - 1) * this.pageN,
        currentindex * this.pageN
      )
    },

    async ajaxH(id , i) {
      this.active =  i
      if (!id ) {
        const { data: res } = await this.$http.get(
          'websiteapi/cmsArticle/page?position=competition_website'
        )
        this.allNewsList = res.data.records
        this.newsList = res.data.records.slice(0, this.pageN)
        //  console.log(res.data.records)
        this.total = res.data.records.length
      } else {
        const { data: res } = await this.$http.get(
          'websiteapi/cmsArticle/page?position=competition_website&categoryId=' +
            id
        )
        this.allNewsList = res.data.records
        this.newsList = res.data.records.slice(0, this.pageN)
        this.total = res.data.records.length
      }

      if (this.total / this.pageN > 1) {
        this.pageShow = true
        this.currentPage = 1
      } else {
        this.pageShow = false
      }
    },
    async listH() {
      const { data: res } = await this.$http.get(
        'websiteapi/cmscategory/list?position=competition_website'
      )
      // console.log(res.data)
      this.tabList = res.data

      // console.log(this.tabList)
    },
    toDet(id) {
      this.$router.push({
        path: '/news_det',
        // name: 'mallList',
        query: {
          id: id,
        },
      })
    },

  },
}
</script>

<style scoped lang="scss">
.page {
  background-color: rgb(255, 255, 255);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .view_1 {
    margin-top: 0.32rem;
    width: 13.02rem;
    max-width: 100%;
    padding-bottom: 0.72rem;



.tab_box{
  > div{
    cursor: pointer;
    transition: background-colo .5s ease;
    span{
      transition: color .5s ease;
      color: rgb(113, 114, 114);
    }
    &:hover{
      background-color: rgb(248, 248, 248) !important;
     
    }
    &.on{
      cursor: auto;
      
      background-color: rgba(255, 63, 61, 1) !important;
      span{
        color: #fff;
      }
    }
  }
}
    .text-wrapper {
      padding: 0.18rem 0;
      
      width: 0.58rem;
      height: 0.5rem;

      .text_7 {
        line-height: 0.14rem;
      }
    }

    .text-wrapper_2 {
      padding: 0.18rem 0;
     
      width: 0.86rem;
      height: 0.5rem;

      &:last-child {
        background-color: transparent;
      }

      .text_8 {
        line-height: 0.14rem;
      }
    }

    .font_5 {
      font-size: 0.14rem;
      font-family: PingFangSC;
      line-height: 0.15rem;
      color: rgb(113, 114, 114);
    }

    .text_9 {
      margin-left: 0.17rem;
      line-height: 0.14rem;
    }

    .list {
      position: relative;
      margin-top: 0.32rem;

      .section_2 {
        border-radius: 0.05rem;
        width: calc((100% - 0.64rem) / 3);
        position: relative;
        cursor: pointer;

        &:hover {
          .pub_img_box {
            img {
              transform: scale(1.1);
            }
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          width: calc((100% - 0.32rem) / 2);
          .pub_img_box {
            &::before {
              margin-top: 56.22%;
            }
          }
        }

        margin-right: 0.32rem;
        margin-bottom: 0.32rem;

        &:nth-child(3n + 2) {
          margin-right: 0;
        }

        .pub_img_box {
          &::before {
            margin-top: 56.31%;
          }

          &::after {
            content: '';
            display: block;
            background-color: rgba($color: #000000, $alpha: 0.3);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
          }
        }

        .new_con_t {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 0.3rem;
          z-index: 3;
        }

        .text-wrapper_3 {
          padding: 0.05rem 0;
          background-color: rgb(31, 177, 138);
          border-radius: 0.03rem;
          align-self: flex-start;

          .font_6 {
            font-size: 0.12rem;
            font-family: PingFangSC;
            line-height: 0.12rem;
            color: rgb(255, 255, 255);
          }

          .text_29 {
            margin: 0 0.06rem;
          }
        }
        .text-wrapper_4 {
          padding: 0.05rem 0;
          background-color: rgba(22, 113, 237, 1);
          border-radius: 0.03rem;
          align-self: flex-start;

          .font_6 {
            font-size: 0.12rem;
            font-family: PingFangSC;
            line-height: 0.12rem;
            color: rgb(255, 255, 255);
          }

          .text_29 {
            margin: 0 0.06rem;
          }
        }

        .space-y-11 {
          & > *:not(:first-child) {
            margin-top: 0.11rem;
          }

          .font_7 {
            font-size: 0.2rem;
            font-family: PingFangSC;
            line-height: 0.21rem;
            color: rgb(255, 255, 255);
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .font_4 {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #fff;
          }
        }
      }
    }

    .group_14 {
      flex-wrap: wrap;
    }

    .font_4 {
      font-size: 0.14rem;
      font-family: PingFangSC;
      line-height: 0.15rem;
      
    }

    .space-x-12 {
      & > *:not(:first-child) {
        margin-left: 0.12rem;
      }

      .section_10 {
        flex: 1 1 0.36rem;
        background-color: rgb(238, 238, 238);
        border-radius: 0.03rem;
        width: 0.36rem;
        height: 0.36rem;
      }

      .text-wrapper_5 {
        padding: 0.12rem 0;
        flex: 1 1 0.36rem;
        background-color: rgb(255, 63, 61);
        border-radius: 0.03rem;
        width: 0.36rem;
        height: 0.36rem;

        .text_12 {
          line-height: 0.12rem;
        }
      }

      .text-wrapper_6 {
        flex: 1 1 0.36rem;
        padding: 0.12rem 0;
        background-color: rgb(238, 238, 238);
        border-radius: 0.03rem;
        width: 0.36rem;
        height: 0.36rem;

        .font_8 {
          font-size: 0.14rem;
          font-family: PingFangSC;
          line-height: 0.12rem;
          color: rgb(113, 114, 114);
        }
      }
    }

    ::v-deep .van-pagination {
      justify-content: center;
      .van-pagination__item {
        color: rgba(113, 114, 114, 1);
        background: #eeeeee;
        margin: 0 0.06rem;
        border-radius: 0.03rem;
        flex: 0;
        transition: color .5s ease, background-color .5s ease;
        &:hover{
          color: #ffffff;
        background-color: rgba(255, 63, 61, 1);
        }
      }
      .van-pagination__item--active {
        color: #ffffff;
        background-color: rgba(255, 63, 61, 1);
      }
    }
  }

  .space-y-46 {
    & > *:not(:first-child) {
      margin-top: 0.46rem;
    }
  }

  .section_11 {
    margin-top: 0.72rem;
    padding: 0.85rem 0 1.11rem;
    background-color: rgb(46, 46, 46);

    .group_5 {
      margin-left: 5.28rem;

      .text_13 {
        color: rgb(255, 63, 62);
        font-size: 0.36rem;
        font-family: AkzidenzGroteskBQ;
        line-height: 0.26rem;
      }

      .text_14 {
        color: rgb(253, 248, 247);
        font-size: 0.24rem;
        font-family: PingFang;
        line-height: 0.23rem;
      }
    }

    .space-y-17 {
      & > *:not(:first-child) {
        margin-top: 0.17rem;
      }
    }

    .group_6 {
      width: 8.88rem;

      .space-y-10 {
        & > *:not(:first-child) {
          margin-top: 0.1rem;
        }

        .image_5 {
          width: 1.06rem;
          height: 0.68rem;
        }

        .image_7 {
          width: 0.95rem;
          height: 0.34rem;
        }

        .text_18 {
          font-size: 0.17rem;
          line-height: 0.12rem;
        }

        .text_19 {
          color: rgb(255, 255, 255);
          font-size: 0.29rem;
          font-family: DINNextLTPro;
          font-weight: 500;
          line-height: 0.21rem;
          letter-spacing: -0.012rem;
        }

        .text_32 {
          line-height: 0.17rem;
        }

        .text_22 {
          line-height: 0.16rem;
          letter-spacing: -0.0064rem;
          text-transform: uppercase;
          opacity: 0.8;
        }
      }

      .group_7 {
        margin-left: 0.72rem;
        margin-top: 0.09rem;
        width: 1.2rem;

        .text_16 {
          color: rgb(255, 255, 255);
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 300;
          line-height: 0.24rem;
        }

        .group_10 {
          line-height: 0.3rem;

          .text_20 {
            opacity: 0.41;
          }
        }
      }

      .space-y-15 {
        & > *:not(:first-child) {
          margin-top: 0.15rem;
        }
      }

      .group_8 {
        margin-left: 0.8rem;
        margin-top: 0.11rem;
        width: 5.1rem;

        .space-y-7 {
          & > *:not(:first-child) {
            margin-top: 0.07rem;
          }

          .group_11 {
            padding: 0 0.2rem;

            .font_10 {
              font-size: 0.12rem;
              font-family: Source Han Sans CN;
              line-height: 0.12rem;
              font-weight: 300;
              color: rgb(255, 255, 255);
            }
          }

          .space-x-41 {
            & > *:not(:first-child) {
              margin-left: 0.41rem;
            }
          }
        }

        .group_9 {
          margin-bottom: 0.04rem;

          .space-x-16 {
            & > *:not(:first-child) {
              margin-left: 0.16rem;
            }

            .image_8 {
              margin-top: 0.03rem;
              width: 0.14rem;
              height: 0.41rem;
            }
          }
        }

        .space-y-23 {
          & > *:not(:first-child) {
            margin-top: 0.23rem;
          }
        }

        .text_17 {
          text-transform: uppercase;
        }
      }

      .font_9 {
        font-size: 0.16rem;
        font-family: PingFang;
        line-height: 0.3rem;
        color: rgb(255, 255, 255);
      }

      .text_15 {
        opacity: 0.8;
      }
    }
  }

  .space-y-72 {
    & > *:not(:first-child) {
      margin-top: 0.72rem;
    }
  }

  .space-x-7 {
    & > *:not(:first-child) {
      margin-left: 0.07rem;
    }

    .text_4 {
      color: rgb(62, 58, 57);
      font-size: 0.2rem;
      font-family: PingFangSC;
      line-height: 0.16rem;
    }

    .font_2 {
      font-size: 0.2rem;
      font-family: PingFangSC;
      line-height: 0.21rem;
      color: rgb(113, 114, 114);
    }

    .text {
      color: rgb(62, 58, 57);
      line-height: 0.2rem;
    }

    .image_6 {
      width: 0.97rem;
      height: 0.97rem;
    }
  }
  @media screen and (orientation: portrait) {
    .view_1 {
      margin: 0;
      padding: 0.4rem 0.3rem 1rem;
      .text-wrapper {
        padding-top: 0.24rem;
        padding-bottom: 0.24rem;
        width: 0.88rem;
        height: 0.7rem;
        .text_7 {
          font-size: 0.24rem;
          line-height: 1;
        }
      }
      .text-wrapper_2 {
        padding: 0.24rem 0;
        width: 1.36rem;
        height: 0.7rem;
        .text_8 {
          font-size: 0.24rem;
          line-height: 1;
        }
      }

      .list {
        margin-top: 0.4rem;
        display: block;
        .section_2 {
          width: 100%;
          margin-bottom: 0;
          margin-right: 0;

          &:nth-child(1),
          &:nth-child(2) {
            width: 100%;
          }
          .new_con_t {
            height: auto;
            padding: 0;
            position: initial;

            .pub-items-start {
              padding: 0.3rem 0 0.4rem;
            }
          }
          .text-wrapper_4,
          .text-wrapper_3 {
            position: absolute;
            left: 0.3rem;
            top: 0.3rem;
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            .text_29 {
              margin: 0 0.12rem;
              font-size: 0.2rem;
              line-height: 1;
            }
          }

          .space-y-11 {
            .font_7 {
              font-size: 0.32rem;
              font-weight: 700;
              line-height: 1;

              color: #717272;
            }
            .font_4 {
              margin-top: 0.16rem;
              font-size: 0.24rem;
              line-height: 1;
              color: #717272;
            }
          }
        }
      }
    }
  }
}
</style>