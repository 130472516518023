import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../pages/index/index.vue';
import News_det from '../pages/news_det/news_det.vue';
import News from '../pages/news/news.vue';
import About from '../pages/about/about.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/news_det',
    name: 'news_det',
    component: News_det,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;