<template>
  <div class="pub-flex-col page">
    <navbox></navbox>
    <div class="pub-flex-col">
      <div class="pub-flex-row pub-justify-between section_001">
        <div
          class="
            pub-flex-row pub-items-start pub-self-start
            group_3
            space-x-177
          "
        >
          <div class="pub-flex-col pub-items-start space-y-31">
            <span class="text_5">Real-time info</span>
            <span class="text_6">大美云集帮您直连赛事</span>
          </div>
        </div>
        <div
          class="pub-flex-col pub-justify-start pub-items-end image-wrapper"
        ></div>
      </div>
      <div class="pub-flex-col pub-justify-start pub-items-center section_2">
        <div class="pub-flex-col section_3 page">
          <span class="pub-self-start text_5">{{ title }}</span>
          <div class="pub-flex-row pub-self-start group_4 space-x-22">
            <span class="font_4 text_6">{{ name }}</span>
            <span class="font_4 text_7">{{ time }}</span>
          </div>
          <div class="divider"></div>
          <!-- <span class="pub-self-start font_4 text_8">尊敬的用户：</span> -->
          <div class="pub-self-start text_9" v-html="con"></div>
        </div>
      </div>
    </div>
    <foot-box></foot-box>
  </div>
</template>

<script>
import Navbox from '../../components/navbox/navbox.vue'
import FootBox from '../../components/footbox/footbox.vue'
export default {
  components: { Navbox, FootBox },
  data() {
    return {
      title: '',
      time: '',
      name: '',
      con: [],
      Version:2,
    }
  },
  created() {
    this.getParams();
    if(this.$wechat.isWeixin()){
				let shareObj = {
					
          title: this.Version == 1 ? '赛事系统' : '赛事管理系统', // 分享标题
							desc: this.Version == 1 ? '集技术的先进性、管理的有效性于一体的高效管理信息系统。' :
								'大美云集学会/协会/商会/企业赛事活动系统简介', // 分享描述
							link: location.href, // 分享链接，该链接必须与当前页面路径一致
							imgUrl: window.location.origin + '/static/img/shareLogo.jpg', // 分享图标

				}
				this.$wechat.wxShare(shareObj)
			}
  },
    mounted() {
  
  },
  methods: {
    getParams() {
      // 取到路由带过来的参数
      const routerParams = this.$route.query.id
      // 将数据放在当前组件的数据内
      // this.mallInfo.searchMap.mallCode = routerParams;

      this.ajaxH(routerParams)
    },
    async ajaxH(id) {
      const { data: res } = await this.$http.get(
        'websiteapi/cmsArticle/' + id + '?position=competition_website'
      )

      this.title = res.data.title
      this.time = res.data.updateTime
      this.name = res.data.categoryName
      this.con = res.data.content
    },
    stringToHTML(str) {
      var dom = document.createElement('div')
      dom.innerHTML = str
      return dom
    },

  },
}
</script>

<style scoped lang="scss">
.page {
  background-color: rgb(255, 255, 255);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .section_2 {
    padding: 0.32rem 0;
    background-color: rgb(246, 246, 246);
    .section_3 {
      padding: 0.32rem 0.24rem 2rem;
      background-color: rgb(255, 255, 255);
      border-radius: 0.05rem;
      width: 13.02rem;
      max-width: 100%;
      .text_5 {
        margin-left: 0.08rem;
        color: rgb(39, 39, 39);
        font-size: 0.32rem;
        font-family: PingFangSC;
        font-weight: 500;
        line-height: 1.25;
      }
      .group_4 {
        margin-left: 0.09rem;
        margin-top: 0.24rem;
        .text_6 {
          color: rgb(64, 135, 252);
        }
        .text_7 {
          color: rgb(153, 153, 153);
          line-height: 0.12rem;
        }
      }
      .space-x-22 {
        & > *:not(:first-child) {
          margin-left: 0.22rem;
        }
      }
      .divider {
        margin-top: 0.19rem;
        background-color: rgb(204, 204, 204);
        height: 0.01rem;
      }
      .font_4 {
        font-size: 0.14rem;
        font-family: PingFangSC;
        line-height: 0.14rem;
      }

      .text_9 {
        margin-left: 0.07rem;
        margin-top: 0.31rem;
        color: rgb(102, 102, 102);
        font-size: 0.14rem;
        font-family: PingFangSC;
        line-height: 0.15rem;
        width: 100%;

        ::v-deep img {
          max-width: 100%;
          height: auto !important;
        }
      }
    }
  }
  @media screen and (orientation: portrait) {
    .section_2 {
      padding: 0.3rem 0.3rem 0.6rem;
      .section_3 {
        padding: 0.4rem 0.3rem;
        .space-x-22 > *:not(:first-child) {
          margin-left: 0.3rem;
          line-height: 1;
        }
        .text_5 {
          margin-left: 0;
          font-size: 0.4rem;
          line-height: 1.2;
          color: #272727;
          font-weight: 700;
        }
        .group_4 {
          margin-top: 0.27rem;
          margin-left: 0;
        }
        .font_4 {
          font-size: 0.24rem;
          line-height: 1;
        }
        .divider {
          margin-top: 0.29rem;
          margin-left: -0.3rem;
          margin-right: -0.3rem;
          padding: 0 0.3rem;
        }
        .text_9 {
          margin-left: 0;
          font-size: 0.28rem;
          line-height: 2;
          color: #666666;

          p{
            margin: 0;
          }
        }
      }
    }
  }
}
</style>